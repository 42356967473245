import React from 'react';
import { Box, Typography, Card } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';

const testimonials = [
  { feedback: 'Se sentir chez-soi tellement le détail est soigné, un cadre reposant et permettant une immersion au cœur de la nature exceptionnelle.', name: 'Laura' },
  { feedback: 'Séjour exceptionnel! Un cadre magnifique et un service impeccable.', name: 'Carol' },
  { feedback: 'L’emplacement, le calme, la propreté et le service apporté. Le logement dispose de beaucoup d’équipements ! C’est atypique nous avons beaucoup aimer.', name: 'Romain' },
];

const Testimonials = () => {
  return (
    <Box sx={{ py: 8, backgroundColor: '#f5f5f5', px: 2 }}>
      {/* Section Title */}
      <Typography 
        component="h2" 
        sx={{
          fontFamily: 'Playfair Display',
          fontSize: '2.5rem',
          color: '#1a1a1a',
          textAlign: 'center',
          mb: 6,
        }}
      >
        Ce que disent nos clients
      </Typography>

      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        pagination={{ clickable: true }}
        navigation
        loop={true}
        autoplay={{ delay: 5000 }} 
        modules={[Pagination, Navigation, Autoplay]}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <Card
              sx={{
                padding: '2.5rem',
                textAlign: 'center',
                backgroundColor: 'white',
                boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                borderRadius: '12px',
                maxWidth: '1000px',
                margin: '0 auto',
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-10px)',
                },
              }}
            >
              {/* Testimonial Feedback */}
              <Typography
                sx={{
                  fontFamily: 'Raleway',
                  fontSize: '1.3rem',
                  color: '#666',
                  mb: 3,
                  fontStyle: 'italic',
                }}
              >
                "{testimonial.feedback}"
              </Typography>

              {/* Client Name */}
              <Typography
                sx={{
                  fontFamily: 'Playfair Display',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: '#1a1a1a',
                }}
              >
                {testimonial.name}
              </Typography>

              {/* Decorative element */}
              <Box
                sx={{
                  width: '60px',
                  height: '2px',
                  backgroundColor: '#c19a6b',
                  margin: '20px auto',
                }}
              />
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default Testimonials;
