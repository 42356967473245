import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import LoadingScreen from './animations/LoadingScreen';
import Acceuil from './pages/Acceuil';
import NosChalets from './pages/Nos-chalets';
import Contact from './pages/Contact';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import PageTransition from './animations/PageTransition';
import PiscineDetente from './components/services/Piscine-detente';
import PetitDejeuner from './components/services/Petit-dejeuner';
import VeloBarbecue from './components/services/Velo-barbecue';
import BaladeCheval from './components/services/Balade-cheval';
import PlageOstriconi from './components/services/Plage-ostriconi';
import GolfReginu from './components/services/Golf-reginu';
import AubergeTesa from './components/services/Auberge-tesa';

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route 
          path="/Acceuil" 
          element={
            <PageTransition>
              <Acceuil />
            </PageTransition>
          } 
        />
        <Route 
          path="/Nos-chalets" 
          element={
            <PageTransition>
              <NosChalets />
            </PageTransition>
          } 
        />
        <Route 
          path="/Contact" 
          element={
            <PageTransition>
              <Contact />
            </PageTransition>
          } 
        />
        <Route
          path="/services/piscine-detente"
          element={
            <PageTransition>
              <PiscineDetente />
            </PageTransition>
          }
        />
        <Route 
          path="/services/petit-dejeuner" 
          element={
            <PageTransition>
              <PetitDejeuner />
            </PageTransition>
          }
        />
        <Route 
          path="/services/velo-barbecue" 
          element={
            <PageTransition>
              <VeloBarbecue />
            </PageTransition>
          }
        />
        <Route 
          path="/services/balade-cheval" 
          element={
            <PageTransition>
              <BaladeCheval />
            </PageTransition>
          }
        />
        <Route 
          path="/services/plage-ostriconi" 
          element={
            <PageTransition>
              <PlageOstriconi />
            </PageTransition>
          }
        />
        <Route 
          path="/services/golf-reginu" 
          element={
            <PageTransition>
              <GolfReginu />
            </PageTransition>
          }
        />
        <Route 
          path="/services/auberge-tesa" 
          element={
            <PageTransition>
              <AubergeTesa />
            </PageTransition>
          }
        />
        <Route 
          path="/" 
          element={
            <PageTransition>
              <Acceuil />
            </PageTransition>
          } 
        />
      </Routes>
    </AnimatePresence>
  );
};

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <Header />
      <AnimatedRoutes />
      <Footer />
    </div>
  );
};

export default App;