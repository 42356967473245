import React from 'react';
import { Box, Container, Typography, Card, Grid } from '@mui/material';
import CabinIcon from '@mui/icons-material/Cabin';
import NatureIcon from '@mui/icons-material/Nature';
import HotelIcon from '@mui/icons-material/Hotel';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const HomeFeatures = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const features = [
    {
      icon: <CabinIcon sx={{ fontSize: { xs: 32, md: 40 }, color: '#c19a6b' }} />,
      title: "Charme Rustique & Confort Moderne",
      description: "Découvrez nos chalets alliant authenticité et confort moderne pour une expérience unique"
    },
    {
      icon: <NatureIcon sx={{ fontSize: { xs: 32, md: 40 }, color: '#c19a6b' }} />,
      title: "Immersion Nature Corse",
      description: "Profitez d'un cadre naturel exceptionnel au cœur de la nature corse"
    },
    {
      icon: <HotelIcon sx={{ fontSize: { xs: 32, md: 40 }, color: '#c19a6b' }} />,
      title: "Luxe Redéfini",
      description: "Une expérience hôtelière repensée pour votre plus grand confort"
    },
    {
      icon: <LocalCafeIcon sx={{ fontSize: { xs: 32, md: 40 }, color: '#c19a6b' }} />,
      title: "Services Premium",
      description: "Petit-déjeuner inclus et services personnalisés pour un séjour inoubliable"
    }
  ];

  const CustomButton = ({ children }) => {
    const navigate = useNavigate();
    const handleClick = () => {
      navigate('/nos-chalets');
      window.scrollTo(0, 0);
    };
  
    return (
      <button
        onClick={handleClick}
        style={{
          backgroundColor: 'transparent',
          color: '#666',
          padding: '12px 24px',
          border: '1px solid #666',
          borderRadius: '4px',
          fontFamily: 'Raleway',
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          position: 'relative',
          overflow: 'hidden'
        }}
        onMouseEnter={(e) => {
          e.target.style.color = '#c19a6b';
          e.target.style.borderColor = '#c19a6b';
          e.target.style.transform = 'translateY(-2px)';
          e.target.style.boxShadow = '0 4px 12px rgba(193, 154, 107, 0.15)';
        }}
        onMouseLeave={(e) => {
          e.target.style.color = '#666';
          e.target.style.borderColor = '#666';
          e.target.style.transform = 'translateY(0)';
          e.target.style.boxShadow = 'none';
        }}
      >
        {children}
      </button>
    );
  };

  const FeatureImage = ({ src, alt }) => (
    <Box
      component="img"
      src={src}
      alt={alt}
      sx={{
        width: '100%',
        height: { xs: '250px', sm: '300px', md: '350px' },
        objectFit: 'cover',
        borderRadius: '8px',
        boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        mb: { xs: 4, md: 0 }
      }}
    />
  );

  return (
    <Box 
      id="features-section" 
      sx={{ 
        py: { xs: 4, sm: 6, md: 8 },
        px: { xs: 2, sm: 4, md: 0 },
        backgroundColor: '#f5f5f5',
        overflow: 'hidden'
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ textAlign: 'center', mb: { xs: 4, sm: 5, md: 6 } }}>
          <Typography
            component="h2"
            sx={{
              fontFamily: 'Playfair Display',
              fontSize: { xs: '2rem', sm: '2.25rem', md: '2.5rem' },
              color: '#1a1a1a',
              mb: 2,
              lineHeight: 1.2
            }}
          >
            Échappée Romantique en Corse
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Raleway',
              fontSize: { xs: '1rem', md: '1.1rem' },
              color: '#666',
              maxWidth: '800px',
              margin: '0 auto',
              px: { xs: 2, sm: 0 }
            }}
          >
            Découvrez nos chalets en bois nichés dans un écrin de nature corse, alliant charme authentique et confort moderne
          </Typography>
        </Box>

        <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                elevation={0}
                sx={{
                  textAlign: 'center',
                  padding: { xs: '1.5rem', md: '2rem' },
                  height: '100%',
                  backgroundColor: 'white',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                  }
                }}
              >
                <Box sx={{ mb: { xs: 1.5, md: 2 } }}>
                  {feature.icon}
                </Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: 'Playfair Display',
                    fontSize: { xs: '1.1rem', md: '1.25rem' },
                    mb: 1,
                    color: '#1a1a1a'
                  }}
                >
                  {feature.title}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Raleway',
                    fontSize: { xs: '0.9rem', md: '1rem' },
                    color: '#666'
                  }}
                >
                  {feature.description}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mt: { xs: 6, md: 8 }, mb: { xs: 6, md: 8 } }}>
          <Grid container spacing={{ xs: 4, md: 6 }} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box sx={{ 
                pl: { md: 4 },
                mb: { xs: 4, md: 0 },
                position: 'relative',
                '&::before': {
                  content: '""',
                  display: { xs: 'none', md: 'block' },
                  position: 'absolute',
                  left: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  width: '2px',
                  height: '80%',
                  background: 'linear-gradient(to bottom, transparent, #c19a6b, transparent)',
                }
              }}>
                <Box sx={{ pl: { md: 4 } }}>
                  <Typography
                    variant="h3"
                    sx={{
                      fontFamily: 'Playfair Display',
                      fontSize: { xs: '1.75rem', sm: '2rem', md: '2.5rem' },
                      mb: { xs: 1.5, md: 2 },
                      color: '#1a1a1a',
                      lineHeight: 1.2
                    }}
                  >
                    Le luxe redéfini
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Raleway',
                      fontSize: { xs: '0.9rem', md: '1rem' },
                      mb: { xs: 2, md: 3 },
                      color: '#666'
                    }}
                  >
                    Nos chalets sont conçus pour vous offrir une expérience de séjour unique, où le luxe se mêle à la simplicité de la vie en pleine nature. Découvrez votre paradis privé au cœur de la Corse.
                  </Typography>
                  <CustomButton>En savoir plus</CustomButton>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <FeatureImage
                src="/i3.JPG"
                alt="Intérieur luxueux du chalet"
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: { xs: 6, md: 8 } }}>
          <Grid container spacing={{ xs: 4, md: 6 }} alignItems="center">
            {isMobile ? (
              <>
                <Grid item xs={12}>
                  <FeatureImage
                    src="/poney.PNG"
                    alt="Vue sur les poneys depuis le chalet"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <Typography
                      variant="h3"
                      sx={{
                        fontFamily: 'Playfair Display',
                        fontSize: { xs: '1.75rem', sm: '2rem', md: '2.5rem' },
                        mb: { xs: 1.5, md: 2 },
                        color: '#1a1a1a',
                        lineHeight: 1.2
                      }}
                    >
                      Une expérience insolite
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Raleway',
                        fontSize: { xs: '0.9rem', md: '1rem' },
                        mb: { xs: 2, md: 3 },
                        color: '#666'
                      }}
                    >
                      Vivez une expérience unique : dormez face aux poneys en pleine nature corse. Un réveil magique avec une vue imprenable sur vos voisins à quatre pattes.
                    </Typography>
                    <CustomButton>Découvrir cette expérience</CustomButton>
                  </Box>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <FeatureImage
                    src="/poney.PNG"
                    alt="Vue sur les poneys depuis le chalet"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ 
                    pl: { md: 4 },
                    position: 'relative',
                    '&::before': {
                      content: '""',
                      display: { xs: 'none', md: 'block' },
                      position: 'absolute',
                      left: 0,
                      top: '50%',
                      transform: 'translateY(-50%)',
                      width: '2px',
                      height: '80%',
                      background: 'linear-gradient(to bottom, transparent, #c19a6b, transparent)',
                    }
                  }}>
                    <Box sx={{ pl: { md: 4 } }}>
                      <Typography
                        variant="h3"
                        sx={{
                          fontFamily: 'Playfair Display',
                          fontSize: { xs: '1.75rem', sm: '2rem', md: '2.5rem' },
                          mb: { xs: 1.5, md: 2 },
                          color: '#1a1a1a',
                          lineHeight: 1.2
                        }}
                      >
                        Une expérience insolite
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'Raleway',
                          fontSize: { xs: '0.9rem', md: '1rem' },
                          mb: { xs: 2, md: 3 },
                          color: '#666'
                        }}
                      >
                        Vivez une expérience unique : dormez face aux poneys en pleine nature corse. Un réveil magique avec une vue imprenable sur vos voisins à quatre pattes.
                      </Typography>
                      <CustomButton>Découvrir cette expérience</CustomButton>
                    </Box>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default HomeFeatures;